import React, { memo, useContext } from 'react'
import { trackingUTM } from '../../../helpers/utmTracking'
import { isMobile as isMobileJS } from '../../../helpers'
import AppContext from '../../../context/AppContext'
import Image from 'next/legacy/image'
import { BLUR_DEFAULT_IMAGE } from '../../constants'

interface IProps {
	name: string
	logo: string
	url: string
	hasNewJobs: boolean
	companyLength: number
	targetBlank?: boolean
}

const CompanyBlockComponent = (props: IProps) => {
	const {
		name,
		hasNewJobs,
		logo,
		url,
		companyLength,
		targetBlank = false
	} = props
	const { t } = useContext(AppContext)
	const companyListUpto3: boolean = companyLength <= 3 ? true : false

	const areaLabel =
		t('Latest ') +
		`${name} ` +
		t('employment/hiring with high salary & attractive benefits')

	const isMobile = isMobileJS() ? true : false
	const tNewJobText = t('New jobs')
	const targetBlankText = targetBlank ? '_blank' : '_self'

	return (
		<div
			className={`companyBlock view_job_item top_company ${
				companyListUpto3 ? 'padding-16 company-box-3' : ''
			}`}
			style={{ flexBasis: isMobile ? '35%' : `33.33%` }}
		>
			<a
				href={url}
				target={targetBlankText}
				rel='noreferrer'
				onClick={trackingUTM}
			>
				<div
					className='companyBlock__box'
					role='img'
					aria-label={areaLabel}
				>
					<Image
						src={logo}
						placeholder='blur'
						blurDataURL={BLUR_DEFAULT_IMAGE}
						alt={name}
						width={128}
						height={128}
						quality={70}
					/>
					<div className='companyBlock__content'>
						<div className='companyBlock__name is-uppercase'>
							{name}
						</div>
						{hasNewJobs && (
							<span className='companyBlock__tag'>
								{tNewJobText}
							</span>
						)}
					</div>
				</div>
			</a>
		</div>
	)
}

const CompanyBlock = CompanyBlockComponent
export default memo(CompanyBlock)
