import { buildQueryString } from './urlBuilder'
import { callTrackingUTM } from '@microfrontends/react-components'

export function utmHotJob() {
	const utm = {
		utm_source_navi: 'vnw_homepage',
		utm_medium_navi: 'HotJob',
		utm_campaign_navi: 'HotJob',
		utm_term_navi: 'homepage2',
		source: 'homePage'
	}

	return buildQueryString(utm)
}

export function utmTopJob() {
	const utm = {
		utm_source_navi: 'vnw_homepage',
		utm_medium_navi: 'TopJob',
		utm_campaign_navi: 'TopJob',
		utm_term_navi: 'homepage2',
		source: 'homePage'
	}

	return buildQueryString(utm)
}

export function utmRecoJob(jobSource: string) {
	const utm = {
		utm_source_navi: 'vnw_homepage',
		utm_medium_navi: 'TopJobReco',
		utm_campaign_navi: 'TopJobReco',
		utm_content_navi: jobSource,
		utm_term_navi: 'homepage2',
		source: 'homePage'
	}

	return buildQueryString(utm)
}

export function utmHrInsider() {
	const utm = {
		utm_source_navi: 'vnw_homepage'
	}

	return buildQueryString(utm)
}

export function utmBestJobInCategory(catId?: number) {
	const utm = {
		utm_source_navi: 'vnw_homepage',
		utm_medium_navi: catId //catId
	}

	return buildQueryString(utm)
}

export function utmPromotionJob() {
	const utm = {
		utm_source_navi: 'vnw_homepage',
		utm_medium_navi: 'FilterPromotion'
	}

	return buildQueryString(utm)
}
export const trackingUTM = (event: any) => {
	const callTrackingApi = `${process.env.API_DOMAIN}/log-aggregator/v2/track-banner-click`
	callTrackingUTM(event, callTrackingApi)
}
